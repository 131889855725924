import { combineReducers } from 'redux';
import shelfReducer from './shelf/reducer';
import cartReducer from './cart/reducer';
import filtersReducer from './filters/reducer';
import sortReducer from './sort/reducer';
import searchReducer from './search/reducer';

export default combineReducers({
  shelf: shelfReducer,
  cart: cartReducer,
  filters: filtersReducer,
  sort: sortReducer,
  search: searchReducer
});
