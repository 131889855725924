import React from 'react';

export default () => (
  <div className="header">
    <div className="subtitle">Componentes y accesorios</div>
    <img src="logo.png" alt="Logo de la empresa" />
    <div className="subtitle">Rioja 1582, Rosario, Santa Fe</div>
    <div className="contact"><i className="fa fa-phone"></i> <a href="tel:+543414487779">+54 341 4487779</a></div>
    <div className="contact"><i className="fa fa-envelope"></i> <a href="mailto:contacto@radiosurelectronica.com.ar">contacto@radiosurelectronica.com.ar</a></div>
  </div>
);
