import { UPDATE_CART, ADD_PRODUCT, REMOVE_PRODUCT, CHANGE_PRODUCT_QUANTITY } from './actionTypes';

const initialState = {
  products: []
};

export default function (state = initialState, action) {
  const products = [...state.products];
  switch (action.type) {
    case UPDATE_CART:
      return {
        ...state,
        products: action.payload
      };
    case ADD_PRODUCT:
      let productAlreadyInCart = false;
      products.forEach(cp => {
        if (cp.id === action.payload.id) {
          cp.quantity += action.payload.quantity;
          productAlreadyInCart = true;
        }
      });
      if (!productAlreadyInCart) {
        products.push(action.payload);
      }
      return { ...state, products };
    case REMOVE_PRODUCT:
      const index = products.findIndex(p => p.id === action.payload.id);
      products.splice(index, 1);
      return { ...state, products };
    case CHANGE_PRODUCT_QUANTITY:
      const product = products.find(p => p.id === action.payload.id);
      product.quantity = action.payload.quantity;
      return { ...state, products };
    default:
      return state;
  }
}
