import React from 'react';
import { useMediaQuery } from 'react-responsive';

const number = '+54 9 341 5553569';

const WhatsApp = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const target = isMobile ? '_self' : '_blank';
  const href = `https://wa.me/${number.replace(/\s/g, '').replace('+', '')}`;
  return <div className="main-whatsapp-link"><a target={target} href={href}><i className="fa fa-whatsapp"></i></a></div>;
}

export default WhatsApp;
