import React from 'react';
import Header from '../Header';
import WhatsApp from '../WhatsApp';

const App = () => (
  <React.Fragment>
    <Header />
    <WhatsApp />
  </React.Fragment>
);

export default App;
